import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '~/api'
import { useGetStore } from '~/hooks/useGetStore'
import { toastServerError } from '~/utils/error'
import { httpStatusCode } from '~/utils/httpStatusCode'
import { manaberuLink } from '~/utils/links'

export const useAccounts = () => {
  const { data: storeData } = useGetStore({ retry: 1 })
  const router = useRouter()
  const queryClient = useQueryClient()

  const { mutate, isLoading: isLogoutLoading } = useMutation(api.dashboard.dLogoutUser, {
    onSuccess: () => {
      queryClient
        .getQueryCache()
        .findAll()
        .forEach((query) => {
          query.setData(undefined)
          queryClient.invalidateQueries(query.queryKey)
        })
      router.push(manaberuLink.login)
    },
    onError: (err) => {
      toastServerError(err)
    },
  })

  const logout = useCallback(() => {
    if (!confirm('ログアウトしますか？')) return
    mutate({})
  }, [mutate])

  const existsStore = useMemo(() => {
    if (!storeData || !storeData.data || storeData.status === httpStatusCode.notFound) return false
    return true
  }, [storeData])

  return {
    logout,
    isLogoutLoading,
    existsStore,
    currentPath: router.pathname,
  }
}
