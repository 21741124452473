import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
  VFC,
} from 'react'

export type FirstSettingFormValues = {
  storeName: string
  storeURL: string
  profileIcon: File | string
  coverImage: File | string
}

type Context = [
  FirstSettingFormValues | undefined,
  Dispatch<SetStateAction<FirstSettingFormValues | undefined>>
]

const FirstSettingContext = createContext<Context>([
  undefined,
  () => {
    throw new Error('FirstSettingContext is not initialized')
  },
])

export const useFirstSettingContext = () => useContext(FirstSettingContext)

type Props = {
  children: ReactNode
}

export const FirstSettingContextProvider: VFC<Props> = ({ children }) => {
  const [value, setValue] = useState<Context[0]>()

  return (
    <FirstSettingContext.Provider value={[value, setValue]}>
      {children}
    </FirstSettingContext.Provider>
  )
}
