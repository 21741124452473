import { css } from '@linaria/core';
import Link from 'next/link';
import { memo, ReactNode, VFC } from 'react';
import { theme } from '~/style';
import { color } from '~/style/color';
import { manaberuLink } from '~/utils/links';
import { Default } from '../Default';
import { useAccounts } from './useAccounts';
type Props = {
  children: ReactNode;
};

const Accounts: VFC<Props> = ({
  children
}) => {
  const {
    logout,
    isLogoutLoading,
    existsStore,
    currentPath
  } = useAccounts();
  return <Default>
      <div className={inner}>
        <h1 className={title}>アカウント</h1>
        <div className={columns}>
          <nav role="menubar" className={menubar}>
            <div className={menuSection}>
              <div className={menuTitle} id="login-info">
                ログイン情報
              </div>
              <ul className={menu} role="menu" aria-labelledby="login-info">
                <li className={menuitem} role="menuitem">
                  <Link href={manaberuLink.accountEmail}>
                    <a className={link} aria-current={currentPath === manaberuLink.accountEmail ? 'page' : undefined}>
                      メールアドレス設定
                    </a>
                  </Link>
                </li>
                <li className={menuitem} role="menuitem">
                  <Link href={manaberuLink.accountPassword}>
                    <a className={link} aria-current={currentPath === manaberuLink.accountPassword ? 'page' : undefined}>
                      パスワード設定
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
            {existsStore && <div className={menuSection}>
                <div className={menuTitle} id="store-info">
                  ストア情報
                </div>
                <ul className={menu} role="menu" aria-labelledby="store-info">
                  <li className={menuitem} role="menuitem">
                    <Link href={manaberuLink.accountStore}>
                      <a className={link} aria-current={currentPath === manaberuLink.accountStore ? 'page' : undefined}>
                        ストア設定
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>}
            <div className={menuSection}>
              <div className={menuTitle} id="user-info">
                ユーザー情報
              </div>
              <ul className={menu} role="menu" aria-labelledby="user-info">
                <li className={menuitem} role="menuitem">
                  <Link href={manaberuLink.accountProfile}>
                    <a className={link} aria-current={currentPath === manaberuLink.accountProfile ? 'page' : undefined}>
                      プロフィール設定
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
            {
            /* FEATURE */
          }
            {
            /* <div className={menuSection}>
             <div className={menuTitle} id="subscription-info">
               ご契約情報
             </div>
             <ul className={menu} role="menu" aria-labelledby="subscription-info">
               <li className={menuitem} role="menuitem">
                 <Link href="/accounts/credit-card">
                   <a
                     className={link}
                     aria-current={pathname === '/accounts/credit-card' ? 'page' : undefined}
                   >
                     クレカ情報変更
                   </a>
                 </Link>
               </li>
               <li className={menuitem} role="menuitem">
                 <Link href="/accounts/invoices">
                   <a
                     className={link}
                     aria-current={pathname === '/accounts/invoices' ? 'page' : undefined}
                   >
                     請求書のダウンロード
                   </a>
                 </Link>
               </li>
               <li className={menuitem} role="menuitem">
                 <Link href="/accounts/plan">
                   <a
                     className={link}
                     aria-current={pathname === '/accounts/plan' ? 'page' : undefined}
                   >
                     プランを変更
                   </a>
                 </Link>
               </li>
             </ul>
            </div> */
          }
            <div className={logoutButtonContainer}>
              <button className={logoutButton} type="button" onClick={logout} disabled={isLogoutLoading}>
                ログアウト
              </button>
            </div>
          </nav>
          <div className={content}>{children}</div>
        </div>
      </div>
    </Default>;
};

export default memo(Accounts);
const inner = "iysw8h5";
const title = "t1xstx66";
const columns = "c1fee24";
const menubar = "m185ivr7";
const menuSection = "m33f6he";
const menuTitle = "mslj2qe";
const menu = "m1okj32i";
const menuitem = "me5wgkj";
const link = "l1qseck6";
const logoutButtonContainer = "l1my6ubr";
const logoutButton = "l11wzuhp";
const content = "c6lp8yp";

require("./Accounts.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Accounts.tsx");