/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DUser {
  /** @format int64 */
  id: number
  name: string
  email: string
  icon_image_url: string
  icon_image_uuid?: string
  icon_image_name?: string
  email_confirmed: boolean
  plan: Plan
}

export interface DUserPublic {
  /** @format int64 */
  id: number
  name: string
  email: string
  icon_image_url: string

  /** @format date */
  last_login_date: string

  /** @format date */
  register_date: string
}

export interface DInvitedUser {
  /** @format int64 */
  id: number
  email: string
}

export interface DCard {
  last4: string
}

export interface DCoursePublisher {
  /** @format int64 */
  id: number
  title: string
  description: string
  status: PublishStatus
  thumbnail_image_url: string
  thumbnail_image_uuid?: string
  thumbnail_image_name?: string

  /** @format int64 */
  student_count: number
  price: number
  payment_type: PaymentType
}

export interface DCourseStudent {
  /** @format int64 */
  id: number
  title: string
  description: string
  status: PublishStatus
  thumbnail_image_url: string
  price: number
  payment_type: PaymentType
}

export interface DChapter {
  /** @format int64 */
  id: number

  /** @format int64 */
  position: number
  title: string
  status: PublishStatus
}

export interface DContent {
  /** @format int64 */
  id: number

  /** @format int64 */
  position: number
  title: string
  description: string
  status: PublishStatus
  wistia_video_id?: string
  video_time?: string
}

export interface DUserCourseAccess {
  access_status: AccessStatus
  inaccessible_reason?: InaccessibleReason | null
}

export interface DStore {
  /** @format int64 */
  id: number
  name: string
  subdomain?: string | null
  cover_image_url: string
  cover_image_uuid?: string
  cover_image_name?: string
}

export interface DStoreStudent {
  name: string
  subdomain: string
  cover_image_url: string
}

export interface DContact {
  /** @format int64 */
  id: number
  name: string
  email: string
  content: string
}

export interface DUploadField {
  key: string
  success_action_status: string
  acl: string
  policy: string
  'x-amz-credential': string
  'x-amz-algorithm': string
  'x-amz-date': string
  'x-amz-signature': string
}

export interface SStore {
  /** @format int64 */
  id: number
  name: string

  /** @format int64 */
  course_count: number
  cover_image_url: string
}

export interface SUserPublic {
  name: string
  icon_image_url: string
}

export interface SUserCourseAccess {
  access_status: AccessStatus
  inaccessible_reason?: InaccessibleReason | null
}

export interface SCourse {
  /** @format int64 */
  id: number
  title: string
  description: string
  thumbnail_image_url: string
  price: number
  payment_type: PaymentType
}

export interface SChapter {
  /** @format int64 */
  id: number

  /** @format int64 */
  position: number
  title: string
}

export interface SContentPublic {
  /** @format int64 */
  id: number

  /** @format int64 */
  position: number
  title: string
  video_time?: string
}

export interface SContentPrivate {
  /** @format int64 */
  id: number

  /** @format int64 */
  position: number
  title: string
  description: string
  wistia_video_id?: string
  video_time?: string
}

export interface SUser {
  /** @format int64 */
  id: number
  name: string
  email: string
  icon_image_url: string
  plan: Plan
}

export interface SContact {
  /** @format int64 */
  id: number
  name: string
  email: string
  content: string
}

export enum PublishStatus {
  Private = 'private',
  Public = 'public',
}

export enum CourseTemplate {
  Zero = 'zero',
  Online = 'online',
  Mini = 'mini',
  Text = 'text',
}

export enum AccessStatus {
  Accessible = 'accessible',
  Inaccessible = 'inaccessible',
}

export enum InaccessibleReason {
  Type = '',
  Unpurchased = 'unpurchased',
  PaymentFailed = 'payment_failed',
}

export enum PaymentType {
  Once = 'once',
  Separate = 'separate',
  Subscription = 'subscription',
}

export enum Plan {
  Free = 'free',
  Standard = 'standard',
  Pro = 'pro',
}

export enum StripeConnectAccount {
  Unregistered = 'unregistered',
  Registered = 'registered',
  Rejected = 'rejected',
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '/' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      )
      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = (format && this.format) || void 0

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      requestParams.headers.common = { Accept: '*/*' }
      requestParams.headers.post = {}
      requestParams.headers.put = {}

      body = this.createFormData(body as Record<string, unknown>)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title manaberu-api-spec
 * @version 1.0.0
 * @baseUrl /
 *
 * マナベルの API 仕様書
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  dashboard = {
    /**
     * No description
     *
     * @tags dashboard
     * @name DCreateUser
     * @summary ユーザーを作成する
     * @request POST:/dashboard/users
     */
    dCreateUser: (
      data: {
        name: string
        email: string
        password: string
        password_confirmation: string
        plan: Plan
        stripe_token: string
      },
      params: RequestParams = {}
    ) =>
      this.request<DUser, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/users`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DEditUser
     * @summary ユーザー情報を変更する
     * @request PATCH:/dashboard/users
     */
    dEditUser: (
      data: {
        name?: string
        email?: string
        password?: string
        password_confirmation?: string
        icon_image_uuid?: string | null
        icon_image_name?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<DUser, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/users`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DGetCard
     * @summary ユーザーのクレカ情報を取得する
     * @request GET:/dashboard/users/card
     */
    dGetCard: (params: RequestParams = {}) =>
      this.request<DCard, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/users/card`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DEditCard
     * @summary ユーザーのクレカ情報を変更する
     * @request PATCH:/dashboard/users/card
     */
    dEditCard: (data: { stripe_token: string }, params: RequestParams = {}) =>
      this.request<DCard, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/users/card`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DEditPlan
     * @summary ユーザーのプランを変更する
     * @request PATCH:/dashboard/users/plan
     */
    dEditPlan: (data: { plan: Plan }, params: RequestParams = {}) =>
      this.request<{ plan: Plan }, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/users/plan`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description s3に画像をアップロードするために、署名付きURLを発行する。railsを返さずこのURLから画像を直接アップロードする。 アップロードが完了した後、PATCH /dashboard/usersでicon_image_uuidに取得したuuidを指定して更新を行うと、画像が設定される。 uploadしたがuuidを設定しなかった場合はデフォルトの画像になる。
     *
     * @tags dashboard
     * @name DGetUserIconImageUploadUrl
     * @summary アイコン画像upload用のurlを取得
     * @request GET:/dashboard/users/icon_image_upload_url
     */
    dGetUserIconImageUploadUrl: (params: RequestParams = {}) =>
      this.request<{ url: string; uuid: string; fields: DUploadField }, any>({
        path: `/dashboard/users/icon_image_upload_url`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DSendResetPasswordEmail
     * @summary パスワード再設定のメールを送る
     * @request POST:/dashboard/users/password
     */
    dSendResetPasswordEmail: (data: { email: string }, params: RequestParams = {}) =>
      this.request<void, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/users/password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DUpdateResetPassword
     * @summary パスワード再設定のメールからパスワードを更新する
     * @request POST:/dashboard/users/password/{reset_token}
     */
    dUpdateResetPassword: (
      resetToken: string,
      data: { password: string; password_confirmation: string },
      params: RequestParams = {}
    ) =>
      this.request<void, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/users/password/${resetToken}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DResendConfirmationEmail
     * @summary メールアドレス認証メールを再送する
     * @request POST:/dashboard/users/email
     */
    dResendConfirmationEmail: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/dashboard/users/email`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DGetUser
     * @summary ログイン済みのユーザーを取得する
     * @request GET:/dashboard/users/me
     */
    dGetUser: (params: RequestParams = {}) =>
      this.request<DUser, void>({
        path: `/dashboard/users/me`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DActivateUser
     * @summary 新規登録したユーザーのメールアドレスを認証する
     * @request POST:/dashboard/users/activate
     */
    dActivateUser: (data: { token: string }, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/dashboard/users/activate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DGetStore
     * @summary ユーザーが保有するストアを取得する
     * @request GET:/dashboard/users/stores
     */
    dGetStore: (params: RequestParams = {}) =>
      this.request<
        DStore & {
          first_settings_completed: boolean
          stripe_connect_account: StripeConnectAccount
        },
        { errors: { target: string; message: string }[] }
      >({
        path: `/dashboard/users/stores`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DLoginUser
     * @summary ユーザーがログインする
     * @request POST:/dashboard/sessions
     */
    dLoginUser: (data: { email: string; password: string }, params: RequestParams = {}) =>
      this.request<void, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/sessions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DLogoutUser
     * @summary ユーザーがログアウトする
     * @request DELETE:/dashboard/sessions
     */
    dLogoutUser: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/dashboard/sessions`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DCreateStore
     * @summary ストアを作成する
     * @request POST:/dashboard/stores
     */
    dCreateStore: (params: RequestParams = {}) =>
      this.request<DStore, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/stores`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DEditStore
     * @summary ストアの情報を変更する
     * @request PATCH:/dashboard/stores/{store_id}
     */
    dEditStore: (
      storeId: number,
      data: {
        name?: string
        subdomain?: string
        cover_image_uuid?: string | null
        cover_image_name?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<DStore, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/stores/${storeId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description s3に画像をアップロードするために、署名付きURLを発行する。railsを返さずこのURLから画像を直接アップロードする。 アップロードが完了した後、PATCH /dashboard/stores/{store_id}でcover_image_uuidに取得したuuidを指定して更新を行うと、画像が設定される。 uploadしたがuuidを設定しなかった場合はデフォルトの画像になる。
     *
     * @tags dashboard
     * @name DGetStoreCoverImageUploadUrl
     * @summary カバー画像upload用のurlを取得
     * @request GET:/dashboard/stores/{store_id}/cover_image_upload_url
     */
    dGetStoreCoverImageUploadUrl: (storeId: number, params: RequestParams = {}) =>
      this.request<{ url: string; uuid: string; fields: DUploadField }, any>({
        path: `/dashboard/stores/${storeId}/cover_image_upload_url`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DGetUserCourse
     * @summary ストアの講座を一覧で取得する
     * @request GET:/dashboard/stores/{store_id}/courses
     */
    dGetUserCourse: (storeId: number, query?: { limit?: number }, params: RequestParams = {}) =>
      this.request<DCoursePublisher[], void>({
        path: `/dashboard/stores/${storeId}/courses`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DCreateCourse
     * @summary 講座を新規作成する
     * @request POST:/dashboard/stores/{store_id}/courses
     */
    dCreateCourse: (
      storeId: number,
      data: { course_template: CourseTemplate; title: string },
      params: RequestParams = {}
    ) =>
      this.request<
        DCoursePublisher & { chapters: (DChapter & { contents: DContent[] })[] },
        { errors: { target: string; message: string }[] }
      >({
        path: `/dashboard/stores/${storeId}/courses`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DGetCourse
     * @summary 講座の情報を取得する
     * @request GET:/dashboard/stores/{store_id}/courses/{course_id}
     */
    dGetCourse: (storeId: number, courseId: number, params: RequestParams = {}) =>
      this.request<DCoursePublisher & { chapters: (DChapter & { contents: DContent[] })[] }, void>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DEditCourse
     * @summary 講座の情報を編集する
     * @request PATCH:/dashboard/stores/{store_id}/courses/{course_id}
     */
    dEditCourse: (
      storeId: number,
      courseId: number,
      data: {
        title?: string
        description?: string
        thumbnail_image_uuid?: string | null
        thumbnail_image_name?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<
        DCoursePublisher & { chapters: (DChapter & { contents: DContent[] })[] },
        { errors: { target: string; message: string }[] }
      >({
        path: `/dashboard/stores/${storeId}/courses/${courseId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DDeleteCourse
     * @summary 講座を削除する
     * @request DELETE:/dashboard/stores/{store_id}/courses/{course_id}
     */
    dDeleteCourse: (storeId: number, courseId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DEditCoursePrice
     * @summary 講座の価格を設定する
     * @request PATCH:/dashboard/stores/{store_id}/courses/{course_id}/price
     */
    dEditCoursePrice: (
      storeId: number,
      courseId: number,
      data: { price: number },
      params: RequestParams = {}
    ) =>
      this.request<void, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/price`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description s3に画像をアップロードするために、署名付きURLを発行する。railsを返さずこのURLから画像を直接アップロードする。 アップロードが完了した後、PATCH /dashboard/stores/{store_id}/courses/{course_id}でthumbnail_image_uuidに取得したuuidを指定して更新を行うと、画像が設定される。 uploadしたがuuidを設定しなかった場合はデフォルトの画像になる。
     *
     * @tags dashboard
     * @name DGetCourseThumbnailImageUploadUrl
     * @summary カバー画像upload用のurlを取得
     * @request GET:/dashboard/stores/{store_id}/courses/{course_id}/thumbnail_image_upload_url
     */
    dGetCourseThumbnailImageUploadUrl: (
      storeId: number,
      courseId: number,
      params: RequestParams = {}
    ) =>
      this.request<{ url: string; uuid: string; fields: DUploadField }, any>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/thumbnail_image_upload_url`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DSortContent
     * @summary 講座の章とコンテンツを並び替える
     * @request PATCH:/dashboard/stores/{store_id}/courses/{course_id}/sort
     */
    dSortContent: (
      storeId: number,
      courseId: number,
      data: { chapters: { id: number; contents: { id: number }[] }[] },
      params: RequestParams = {}
    ) =>
      this.request<
        (DCoursePublisher & { chapters: (DChapter & { contents: DContent[] })[] })[],
        { errors: { target: string; message: string }[] }
      >({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/sort`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DChangeStatusCourse
     * @summary 講座の公開状況を変更する
     * @request PATCH:/dashboard/stores/{store_id}/courses/{course_id}/status
     */
    dChangeStatusCourse: (
      storeId: number,
      courseId: number,
      data: { status: PublishStatus },
      params: RequestParams = {}
    ) =>
      this.request<DCoursePublisher, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/status`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DCreateChapter
     * @summary 講座に属する章を作成する
     * @request POST:/dashboard/stores/{store_id}/courses/{course_id}/chapters
     */
    dCreateChapter: (
      storeId: number,
      courseId: number,
      data: { title: string },
      params: RequestParams = {}
    ) =>
      this.request<DChapter, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/chapters`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DEditChapter
     * @summary 講座に属する章の情報を編集する
     * @request PATCH:/dashboard/stores/{store_id}/courses/{course_id}/chapters/{chapter_id}
     */
    dEditChapter: (
      storeId: number,
      courseId: number,
      chapterId: number,
      data: { title?: string; status?: PublishStatus },
      params: RequestParams = {}
    ) =>
      this.request<DChapter, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/chapters/${chapterId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DDeleteChapter
     * @summary 講座に属する章を削除する
     * @request DELETE:/dashboard/stores/{store_id}/courses/{course_id}/chapters/{chapter_id}
     */
    dDeleteChapter: (
      storeId: number,
      courseId: number,
      chapterId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/chapters/${chapterId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DCreateContent
     * @summary 講座の章に属するコンテンツを作成する
     * @request POST:/dashboard/stores/{store_id}/courses/{course_id}/chapters/{chapter_id}/contents
     */
    dCreateContent: (
      storeId: number,
      courseId: number,
      chapterId: number,
      data: {
        title: string
        description: string
        status: PublishStatus
        wistia_video_id?: string | null
        video_time?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<DContent, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/chapters/${chapterId}/contents`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DGetContent
     * @summary 講座の章に属するコンテンツを取得する
     * @request GET:/dashboard/stores/{store_id}/courses/{course_id}/chapters/{chapter_id}/contents/{content_id}
     */
    dGetContent: (
      storeId: number,
      courseId: number,
      chapterId: number,
      contentId: number,
      params: RequestParams = {}
    ) =>
      this.request<DContent, void>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/chapters/${chapterId}/contents/${contentId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DEditContent
     * @summary 講座の章に属するコンテンツの情報を編集する
     * @request PATCH:/dashboard/stores/{store_id}/courses/{course_id}/chapters/{chapter_id}/contents/{content_id}
     */
    dEditContent: (
      storeId: number,
      courseId: number,
      chapterId: number,
      contentId: number,
      data: {
        title?: string
        description?: string
        status?: PublishStatus
        wistia_video_id?: string | null
        video_time?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<DContent, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/chapters/${chapterId}/contents/${contentId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DDeleteContent
     * @summary 講座の章に属するコンテンツを削除する
     * @request DELETE:/dashboard/stores/{store_id}/courses/{course_id}/chapters/{chapter_id}/contents/{content_id}
     */
    dDeleteContent: (
      storeId: number,
      courseId: number,
      chapterId: number,
      contentId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/chapters/${chapterId}/contents/${contentId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description wistiaに動画をアップロードするためのアクセストークンを発行しproject_idと共に返す。railsを返さずこのアクセストークンを利用して直接アップロードする。
     *
     * @tags dashboard
     * @name DGetContentVideoUploadConfig
     * @summary 動画upload用のアクセストークン・project_idを取得
     * @request GET:/dashboard/stores/{store_id}/courses/{course_id}/video_upload_config
     */
    dGetContentVideoUploadConfig: (storeId: number, courseId: number, params: RequestParams = {}) =>
      this.request<{ access_token: string; wistia_project_id: string }, any>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/video_upload_config`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DGetVideoUploadAvailability
     * @summary 動画をアップロードできるかの可否を取得する
     * @request GET:/dashboard/stores/{store_id}/courses/{course_id}/video_upload_availability
     */
    dGetVideoUploadAvailability: (storeId: number, courseId: number, params: RequestParams = {}) =>
      this.request<{ availability: boolean }, any>({
        path: `/dashboard/stores/${storeId}/courses/${courseId}/video_upload_availability`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DGetStoreUser
     * @summary ストアに属する受講生一覧を取得する
     * @request GET:/dashboard/stores/{store_id}/users
     */
    dGetStoreUser: (
      storeId: number,
      query?: { offset?: number; course_id?: number; user_email_or_name?: string },
      params: RequestParams = {}
    ) =>
      this.request<{ user_count: number; users: DUserPublic[] }, void>({
        path: `/dashboard/stores/${storeId}/users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DCreateStoreUser
     * @summary 講座に受講生を招待する
     * @request POST:/dashboard/stores/{store_id}/users
     */
    dCreateStoreUser: (
      storeId: number,
      data: { emails: any[]; course_id: number },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          users: (DUserPublic & { user_course_access: DUserCourseAccess })[]
          invited_users: DInvitedUser[]
        },
        void
      >({
        path: `/dashboard/stores/${storeId}/users`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DGetStoreUserCsv
     * @summary ストアに属する受講生一覧をCSV形式で取得する
     * @request GET:/dashboard/stores/{store_id}/users/csv
     */
    dGetStoreUserCsv: (storeId: number, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/dashboard/stores/${storeId}/users/csv`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DGetStoreSales
     * @summary ストアの売り上げを表示する
     * @request GET:/dashboard/stores/{store_id}/sales
     */
    dGetStoreSales: (
      storeId: number,
      query?: { limit?: number; offset?: number },
      params: RequestParams = {}
    ) =>
      this.request<
        { course_name: string; user_name: string; payment_date: string; price: number }[],
        void
      >({
        path: `/dashboard/stores/${storeId}/sales`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description 公開、非公開でも購入したものであれば取得できる。アクセス権限が切れたもの（支払いが失敗したなど）も取得はできるが、コンテンツは表示できない。
     *
     * @tags dashboard
     * @name DGetUserCourseAccess
     * @summary ユーザーが購入した講座を一覧で取得する
     * @request GET:/dashboard/courses
     */
    dGetUserCourseAccess: (query?: { limit?: number }, params: RequestParams = {}) =>
      this.request<
        (DCourseStudent & { store: DStoreStudent; user_course_access: DUserCourseAccess })[],
        void
      >({
        path: `/dashboard/courses`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DCreateContact
     * @summary お問い合わせ
     * @request POST:/dashboard/contacts
     */
    dCreateContact: (
      data: { name: string; email: string; content: string },
      params: RequestParams = {}
    ) =>
      this.request<DContact, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/contacts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DStripeConnectGetCreateUrl
     * @summary StripeConnectのアカウントを作成するURLを返す
     * @request POST:/dashboard/stripe_accounts
     */
    dStripeConnectGetCreateUrl: (
      data: { return_url: string; refresh_url: string },
      params: RequestParams = {}
    ) =>
      this.request<{ url: string }, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/stripe_accounts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DStripeConnectGetLoginUrl
     * @summary StripeConnectのアカウントにログインするURLを返す
     * @request GET:/dashboard/stripe_accounts
     */
    dStripeConnectGetLoginUrl: (params: RequestParams = {}) =>
      this.request<{ url: string }, { errors: { target: string; message: string }[] }>({
        path: `/dashboard/stripe_accounts`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  store = {
    /**
     * No description
     *
     * @tags store
     * @name SGetStore
     * @summary ストア情報を取得
     * @request GET:/store/stores/{subdomain}
     */
    sGetStore: (subdomain: string, params: RequestParams = {}) =>
      this.request<SStore & { user: SUserPublic }, any>({
        path: `/store/stores/${subdomain}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SGetCourses
     * @summary 講座情報一覧を取得する
     * @request GET:/store/stores/{subdomain}/courses
     */
    sGetCourses: (subdomain: string, params: RequestParams = {}) =>
      this.request<(SCourse & { user_course_access: SUserCourseAccess })[], any>({
        path: `/store/stores/${subdomain}/courses`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SGetCourse
     * @summary 講座情報を取得する
     * @request GET:/store/stores/{subdomain}/courses/{course_id}
     */
    sGetCourse: (courseId: number, subdomain: string, params: RequestParams = {}) =>
      this.request<
        SCourse & {
          user_course_access: SUserCourseAccess
          chapters: (SChapter & { contents: (SContentPrivate | SContentPublic)[] })[]
        },
        void
      >({
        path: `/store/stores/${subdomain}/courses/${courseId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SCreateUser
     * @summary ユーザーを作成する
     * @request POST:/store/stores/{subdomain}/users/courses/{course_id}
     */
    sCreateUser: (
      subdomain: string,
      courseId: number,
      data: {
        name: string
        email: string
        password: string
        password_confirmation: string
        plan: Plan
        stripe_token: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SUser, { errors: { target: string; message: string }[] }>({
        path: `/store/stores/${subdomain}/users/courses/${courseId}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SPurchaseCourse
     * @summary ユーザーがログイン時にワンクリック決済をする
     * @request POST:/store/stores/{subdomain}/users/courses/{course_id}/purchase
     */
    sPurchaseCourse: (subdomain: string, courseId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/store/stores/${subdomain}/users/courses/${courseId}/purchase`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SNewInvitedUser
     * @summary 招待ユーザーを作成する講座情報を取得する
     * @request GET:/store/stores/{subdomain}/invited_users/{invitation_token}/course/{course_id}
     */
    sNewInvitedUser: (
      subdomain: string,
      invitationToken: string,
      courseId: number,
      params: RequestParams = {}
    ) =>
      this.request<SCourse, { errors: { target: string; message: string }[] }>({
        path: `/store/stores/${subdomain}/invited_users/${invitationToken}/course/${courseId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SCreateInvitedUser
     * @summary 招待ユーザーを作成する
     * @request POST:/store/stores/{subdomain}/invited_users/{invitation_token}/course/{course_id}
     */
    sCreateInvitedUser: (
      subdomain: string,
      invitationToken: string,
      courseId: number,
      data: { name: string; password: string; password_confirmation: string; stripe_token: string },
      params: RequestParams = {}
    ) =>
      this.request<SUser, { errors: { target: string; message: string }[] }>({
        path: `/store/stores/${subdomain}/invited_users/${invitationToken}/course/${courseId}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SLoginUser
     * @summary ユーザーがログインする
     * @request POST:/store/sessions
     */
    sLoginUser: (data: { email: string; password: string }, params: RequestParams = {}) =>
      this.request<void, { errors: { target: string; message: string }[] }>({
        path: `/store/sessions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SLogoutUser
     * @summary ユーザーがログアウトする
     * @request DELETE:/store/sessions
     */
    sLogoutUser: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/store/sessions`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SSendResetPasswordEmail
     * @summary パスワード再設定のメールを送る
     * @request POST:/store/users/password
     */
    sSendResetPasswordEmail: (data: { email: string }, params: RequestParams = {}) =>
      this.request<void, { errors: { target: string; message: string }[] }>({
        path: `/store/users/password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SGetUser
     * @summary ログイン済みのユーザーを取得する
     * @request GET:/store/users/me
     */
    sGetUser: (params: RequestParams = {}) =>
      this.request<SUser, void>({
        path: `/store/users/me`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags store
     * @name SCreateContact
     * @summary お問い合わせ
     * @request POST:/store/stores/{subdomain}/contacts
     */
    sCreateContact: (
      subdomain: string,
      data: { name: string; email: string; content: string },
      params: RequestParams = {}
    ) =>
      this.request<SContact, { errors: { target: string; message: string }[] }>({
        path: `/store/stores/${subdomain}/contacts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
