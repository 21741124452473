import { NextPage } from 'next';
import { AppProps } from 'next/app';
import 'normalize.css';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '~/components/layouts';
import Maintenance from '~/components/pages/Maintenance';
import { FirstSettingContextProvider } from '~/contexts/FirstSettingContext';
import { SignUpContextProvider } from '~/contexts/SignUpContext';
import '~/global.css';
import { useIntercomChatbot } from '~/hooks/useIntercomChatBot';
import { reactQueryDefaultOptions } from '~/utils/reactQuery';
const isProd = process.env.NODE_ENV === 'production';
const isMaintenance = !!process.env.NEXT_PUBLIC_MAINTENANCE;

const App: NextPage<AppProps> = ({
  pageProps,
  Component
}) => {
  useIntercomChatbot();
  const [queryClient] = useState(() => new QueryClient(reactQueryDefaultOptions));
  const [isPrepared, setIsPrepared] = useState(isProd);
  useEffect(() => {
    if (isProd) return; // 開発環境で msw の初期化前にレンダリングされるのを防ぐ

    import('~/mock').then(async ({
      setupMock
    }) => {
      await setupMock();
      setIsPrepared(true);
    });
  }, []);
  if (!isPrepared) return null;
  if (isMaintenance) return <Maintenance />;
  return <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <SignUpContextProvider>
        <FirstSettingContextProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
          <ToastContainer />
        </FirstSettingContextProvider>
      </SignUpContextProvider>
    </QueryClientProvider>;
};

export default App;