import { AxiosError } from 'axios'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
  VFC,
} from 'react'

export type SignUpFormValues = {
  name: string
  email: string
  password: string
  password_confirmation: string
  cardNumberLast4: string
  cardTokenId: string
  agreement: boolean
  serverErrors?: AxiosError
}

type Context = [
  SignUpFormValues | undefined,
  Dispatch<SetStateAction<SignUpFormValues | undefined>>
]

const SignUpContext = createContext<Context>([
  undefined,
  () => {
    throw new Error('SignUpContext is not initialized')
  },
])

export const useSignUpContext = () => useContext(SignUpContext)

type Props = {
  children: ReactNode
}

export const SignUpContextProvider: VFC<Props> = ({ children }) => {
  const [value, setValue] = useState<Context[0]>()

  return <SignUpContext.Provider value={[value, setValue]}>{children}</SignUpContext.Provider>
}
