import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import { api } from '~/api'
import { cacheKeys } from '~/api/cacheKeys'
import { PUBLIC_ONLY_PATHS } from '~/utils/constants'
import { toastServerError } from '~/utils/error'
import { manaberuLink } from '~/utils/links'

export const useMe = (
  options: {
    retry?: number | boolean
    refetchInterval?: number
  } = {}
) => {
  const router = useRouter()
  return useQuery(cacheKeys.dGetUser, () => api.dashboard.dGetUser(), {
    onSuccess() {
      if (PUBLIC_ONLY_PATHS.some((path) => router.pathname.match(path))) {
        router.replace(manaberuLink.top)
      }
    },
    onError(err) {
      if (PUBLIC_ONLY_PATHS.some((path) => router.pathname.match(path))) return

      toastServerError(err)

      router.replace(manaberuLink.login)
    },
    ...options,
  })
}
