import { css } from '@linaria/core';
import { mdiAccountMultiple, mdiBank, mdiNoteText } from '@mdi/js';
import { useRouter } from 'next/dist/client/router';
import Image from 'next/image';
import Link from 'next/link';
import { memo } from 'react';
import { Spinner } from '~/components/elements/Spinner';
import { useMe } from '~/hooks/useMe';
import { theme } from '~/style';
import { manaberuLink } from '~/utils/links';
import IconLink from '../../elements/IconLink/IconLink';

const Navigation: React.VFC = () => {
  const {
    data: userData
  } = useMe();
  const router = useRouter();
  const currentPath = router.pathname;
  return <nav className={container}>
      <Link href={manaberuLink.courses}>
        <a className={imgWrapper}>
          <Image src="/Logo.png" alt="ロゴ" width={160} height={28.4} />
        </a>
      </Link>

      {!userData?.data ? <div className={center}>
          <Spinner />
        </div> : <>
          <div className={links}>
            <IconLink href={manaberuLink.courses} iconPath={mdiNoteText} selected={currentPath === manaberuLink.top || currentPath === manaberuLink.courses}>
              講座
            </IconLink>
            <IconLink href={manaberuLink.members} iconPath={mdiAccountMultiple} selected={currentPath === manaberuLink.members}>
              メンバー
            </IconLink>
            <IconLink href={manaberuLink.sales} iconPath={mdiBank} selected={currentPath === manaberuLink.sales}>
              売り上げ
            </IconLink>
          </div>

          <div className={accountWrapper}>
            <Link href={manaberuLink.accountEmail}>
              <a className={account}>
                {userData.data.icon_image_url && <Image src={userData.data.icon_image_url} width={60} height={60} className={img} alt="あなたのアイコン" />}
                <div>
                  <div className={userName}>{userData.data.name}</div>
                  <div className={email}>{userData.data.email}</div>
                </div>
              </a>
            </Link>
          </div>

          <div className={contact}>
            <Link href={manaberuLink.contact}>
              <a className={link}>お問い合わせ</a>
            </Link>
            <span>/</span>
            <Link href={manaberuLink.provider}>
              <a className={link}>運営会社</a>
            </Link>
          </div>
        </>}
    </nav>;
};

export default memo(Navigation);
const container = "c1rhlaq5";
const imgWrapper = "i16ftmsi";
const links = "l517d6e";
const account = "a1ctl41z";
const accountWrapper = "a95wspf";
const userName = "url1y46";
const email = "e1y3bowf";
const img = "i1lkefdp";
const link = "l1kwdlyt";
const contact = "c1dez92i";
const center = "c1xfkrbu";

require("./Navigation.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Navigation.tsx");