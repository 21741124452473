import { css } from '@linaria/core';
import { memo, ReactNode, VFC } from 'react';
import { Navigation } from '../Navigation';
export type Props = {
  children: ReactNode;
};

const Default: VFC<Props> = ({
  children
}) => <div className={container}>
    <div className={nav}>
      <Navigation />
    </div>
    <main className={main}>{children}</main>
  </div>;

export default memo(Default);
const container = "clkyivc";
const nav = "n1clz375";
const main = "mttdt5j";

require("./Default.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Default.tsx");