/**
 * メールアドレス
 *
 * {@link https://html.spec.whatwg.org/multipage/input.html#email-state-(type=email)}
 */
export const EMAIL =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

/**
 * URL
 */
export const URL = /^[0-9a-zA-Z-_]*$/

/**
 * パスワード
 *
 * 最低8文字、最大64文字
 * 半角英大文字小文字数字マスト
 * 記号可
 */
export const PASSWORD =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,64}$/

/**
 * ログイン中に開いたらトップにリダイレクトする
 */
export const PUBLIC_ONLY_PATHS = [
  '/login',
  '/sign-up/register/.*',
  '/reset-password',
  '/reset-password/.*',
]
