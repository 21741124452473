import { getScheme } from './getScheme'

export const manaberuLink = {
  top: `/`,
  members: `/members`,
  sales: `/sales`,
  contact: `/contact`,
  login: `/login`,
  resetPassword: `/reset-password`,

  signUpConfirm: `/sign-up/confirm`,
  signUpComplete: `/sign-up/complete`,

  accountEmail: `/accounts/email`,
  accountPassword: `/accounts/password`,
  accountStore: `/accounts/store`,
  accountProfile: `/accounts/profile`,

  courses: `/courses`,
  newCourse: `/courses/new`,
  editCourse: ({ storeId, courseId }: { storeId: string | number; courseId: string | number }) =>
    `/stores/${storeId}/courses/${courseId}/edit`,
  editContent: ({
    storeId,
    courseId,
    chapterId,
    contentId,
  }: {
    storeId: string | number
    courseId: string | number
    chapterId: string | number
    contentId: string | number
  }) => `/stores/${storeId}/courses/${courseId}/chapters/${chapterId}/contents/${contentId}/edit`,
  releasedCourse: ({
    storeId,
    courseId,
  }: {
    storeId: string | number
    courseId: string | number
  }) => `/stores/${storeId}/courses/${courseId}/released`,
  createdCourses: `/courses/created`,
  subscribedCourses: `/courses/subscribed`,

  firstSettingConfirm: `/first-setting/confirm`,
  firstSettingRegister: ({
    courseId,
    storeId,
  }: {
    courseId: string | number
    storeId: string | number
  }) => `/first-setting/register?courseId=${courseId}&storeId=${storeId}`,

  store: (subdomain: string) =>
    `${getScheme()}${subdomain}.${process.env.NEXT_PUBLIC_STORE_DOMAIN}`,
  storeCourse: ({ subdomain, courseId }: { subdomain: string; courseId: string | number }) =>
    `${getScheme()}${subdomain}.${process.env.NEXT_PUBLIC_STORE_DOMAIN}/${courseId}`,

  plan: `${process.env.NEXT_PUBLIC_LP_URL}#plan`,
  publisherRule: `https://impartial-pineapple-829.notion.site/6bcd0c2826024482a6d7113b56e52cf1`,
  userRule: `https://impartial-pineapple-829.notion.site/75fbaff468024a7e98f1b7f6599cbb19`,
  provider: `https://n8tive.works`,
  twitterShare: ({ url, text }: { url: string; text: string }) =>
    `http://twitter.com/share?url=${url}&text=${text}が公開されました！&hashtags=マナベル`,
  productRequestForm:
    'https://docs.google.com/forms/d/e/1FAIpQLSdYXW6B8cGJxhlnqym8IkJyMQK1OxmK1todVsEfgbLiRzJbrQ/viewform',
} as const
