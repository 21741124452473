import MaterialDesignIcon from '@mdi/react'
import { ComponentProps, memo, VFC } from 'react'

export type IconProps = ComponentProps<typeof MaterialDesignIcon> & {
  className?: string
  size?: number
}

const Icon: VFC<IconProps> = ({ size = 24, ...props }) => (
  <MaterialDesignIcon {...props} size={size / 19.32} />
)

export default memo(Icon)
