import { css } from '@linaria/core';
import Link from 'next/link';
import { ComponentProps, memo } from 'react';
import { Icon } from '~/components/elements/Icon';
import { theme } from '~/style';
type Props = ComponentProps<typeof Link> & {
  iconPath: string;
  selected?: boolean;
};

const IconLink: React.VFC<Props> = ({
  iconPath,
  selected = false,
  ...props
}) => <Link href={props.href}>
    <a className={link} data-selected={selected}>
      <div className={dot} data-selected={selected} />
      <Icon path={iconPath} size={28} />
      <span>{props.children}</span>
    </a>
  </Link>;

export default memo(IconLink);
const dot = "d19fonze";
const link = "l1d0zumu";

require("./IconLink.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./IconLink.tsx");