import { css } from '@linaria/core';
import { useRouter } from 'next/dist/client/router';
import { ReactNode, useEffect, useState, VFC } from 'react';
import { useMe } from '~/hooks/useMe';
import { Spinner } from '../elements/Spinner';
import { Accounts } from './Accounts';
import { Default } from './Default';
export type Props = {
  children: ReactNode;
};
const plainLayoutPaths = ['/sign-up/.*', '/contact', '/login', '/reset-password', '/first-setting/.*', '/accounts/confirm-email'];

const isPlainLayoutPaths = (path: string) => plainLayoutPaths.some(notNavPath => path.match(notNavPath));

export const Layout: VFC<Props> = ({
  children
}) => {
  const router = useRouter();
  const [isReady, setIsReady] = useState(false);
  useMe({
    retry: false
  });
  useEffect(() => {
    if (router.isReady) setIsReady(true);
  }, [router.isReady]);

  if (!isReady) {
    return <div className={center}>
        <Spinner />
      </div>;
  }

  if (isPlainLayoutPaths(router.pathname)) return <>{children}</>;
  if (router.asPath.startsWith('/accounts')) return <Accounts>{children}</Accounts>;
  return <Default>{children}</Default>;
};
const center = "cjdyaju";

require("./index.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./index.tsx");