import { QueryClientConfig } from 'react-query'

export const reactQueryDefaultOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchInterval: 5 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 3,
    },
  },
}
