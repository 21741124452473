import { css } from '@linaria/core';
import { memo, VFC } from 'react';
import { color } from '~/style/color';
type Props = {
  size?: 'md' | 'sm';
  colorSchema?: 'white' | 'green';
};

const Spinner: VFC<Props> = ({
  size = 'md',
  colorSchema = 'green'
}) => <div className={loader} data-size={size} data-color={colorSchema}>
    Spinner
  </div>;

export default memo(Spinner);
const loader = "l1fsk8gs";

require("./Spinner.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Spinner.tsx");