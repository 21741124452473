import { useEffect, useRef } from 'react'

export const useIntercomChatbot = () => {
  const loaded = useRef(false)

  useEffect(() => {
    if (loaded.current) return

    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'dbaggtex',
    }

    const intercomScript = document.createElement('script')
    intercomScript.id = 'intercom-script'
    intercomScript.src = 'https://js.intercomcdn.com/shim.latest.js'

    loaded.current = true
    document.body.appendChild(intercomScript)
  }, [])

  return null
}
