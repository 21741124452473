import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { api } from '~/api'
import { cacheKeys } from '~/api/cacheKeys'
import { toastServerError } from '~/utils/error'
import { ApiResponseBody } from '~/utils/types'

export const useGetStore = (
  options: {
    retry?: number
    refetchInterval?: number
    enabled?: boolean
    onSuccess?: (data: AxiosResponse<ApiResponseBody<typeof api.dashboard.dGetStore>>) => void
  } = {}
) => {
  return useQuery(cacheKeys.dGetStore, () => api.dashboard.dGetStore(), {
    ...options,
    onError(err) {
      toastServerError(err)
    },
  })
}
