export const cacheKeys = {
  dGetUser: 'dGetUser',
  dGetStore: 'dGetStore',
  dGetUserCourse: 'dGetUserCourse',
  dGetStoreUser: 'dGetStoreUser',
  dGetCourse: 'dGetCourse',
  dGetContent: 'dGetContent',
  dGetUserCourseAccess: 'dGetUserCourseAccess',
  dGetStoreSales: 'dGetStoreSales',
  dStripeConnectGetCreateUrl: 'dStripeConnectGetCreateUrl',
  dStripeConnectGetLoginUrl: 'dStripeConnectGetLoginUrl',
  dGetContentVideoUploadConfig: 'dGetContentVideoUploadConfig',
  dGetVideoUploadAvailability: 'dGetVideoUploadAvailability',
  dGetStoreUserCsv: 'dGetStoreUserCsv',
} as const
